import React, {Component} from 'react';
import './Square.css'
import {ColorDrop} from "./ColorDrop";

/**
 * @prop [function] spawnSibling - onClick
 */
export default class Square extends Component {
    state = {
        clicked: false,
        clickCounter: 0,
        dropReset: 0
    };
    timer = null;

    onClick = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
            this.setState({clicked: false});
            setTimeout(this.updateClickState, 50);
            return;
        }
        this.updateClickState();
    };

    updateClickState = () => {
        if (this.state.clickCounter === 3) {
            this.props.spawnSibling();
        }
        this.setState({clicked: true, clickCounter: this.state.clickCounter + 1});
        this.timer = setTimeout(this.resetAnimation, 2000)
    };

    resetAnimation = () => {
        clearTimeout(this.timer);
        this.setState({
            clicked: false,
            dropReset: this.props.showColorDrop
            }
        );
    };


    render() {
        const classNames = ['square'];

        const colorDrop = {
            right: '4vw',
            filter: 'blur(20px)'
        };

        if (this.props.showSquare) {
            classNames.push('visibleSquare');
        }
        if (this.state.clicked) {
            classNames.push('wobblingSquare');
        }
        const drops = [];
        if (this.props.showColorDrop) {
            for (let i = 0; i < this.props.showColorDrop - this.state.dropReset; i++) {
                drops.push(<ColorDrop style={colorDrop} key={i}/>)
            }
        }
        return  <div className={classNames.join(' ')} onClick = {this.onClick} >
            {drops}
            </div>
    }
}