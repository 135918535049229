import React, {Component} from 'react';
import './ChildSquare.css'

export default class Square extends Component {
    onClick = () => {
        this.props.spawnSibling();
    };

    render() {
        const classNames = ['childSquare'];
        return <div className={classNames.join(' ')} onClick = {this.onClick} />
    }
}