import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Button from './ui/Buttons'
import Square from './elements/Square'
import ChildSquare from "./elements/ChildSquare";
import {ColorDrop} from "./elements/ColorDrop";


class App extends Component {
  state = {
    showSquare: false,
    showChildSquare: false,
    showColorDrop: 0,
    clickCounter: 0,
    hideButton: false
  };

  spawnSquare = () => {
    this.setState({showSquare: true})
  };

  spawnChildSquare = () => {
    this.setState({showChildSquare: true});
  };

  spawnColorDrop = () => {
    this.setState({showColorDrop: this.state.showColorDrop + 1})
  };

  render() {

    const drops = [];
    if (this.state.showColorDrop) {
      for (let i = 0; i < this.state.showColorDrop; i++) {
        drops.push(<ColorDrop key={i}/>)
      }
    }

    return (
      <div className="App">
        <header className="App-header">
          <Button onClick={this.spawnSquare} hidden={this.state.showSquare}>Start</Button>
          <Square showSquare={this.state.showSquare} spawnSibling={this.spawnChildSquare} showColorDrop={this.state.showColorDrop}/>
          {this.state.showChildSquare ? <ChildSquare spawnSibling={this.spawnColorDrop}/> : null}
          {drops}
        </header>
      </div>
    );
  }
}

export default App;
