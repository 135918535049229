import React, { Component } from 'react';
import './Buttons.css';

/**
 * @prop [string] children
 * @prop {callback} onClick
 */
export default class Button extends Component {
    render() {
        const className = ['button'];
        if (this.props.hidden) {
            className.push('hidden');
        }

        return (
            <div className={className.join(' ')}
                 onClick={this.props.onClick}>
                {this.props.children}
            </div>
        );
    }
}

